import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import CountryModal from '../components/CountryModal';
import { Link } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import CategoryProduct from '../components/CategoryProduct';
import MetaTags from '../utils/MetaTags';
import { useCountry } from '../context/CountryContext';
import {COMMON} from '../constant/constant';

export default function Category() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { selectedCountry } = useCountry();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const pathname = window.location.pathname;
        const slug = pathname.substring(pathname.lastIndexOf('/') + 1);
        const response = await axios.get(`${process.env.REACT_APP_API_URL}category/${slug}`, {
          withCredentials: true,
        });
        setData(response.data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false); 
      }
    };

    fetchData();
  }, [selectedCountry]);

  if (loading) {
    return (
      <div className='catloader'>
        <img src={`${COMMON}wallfleur.loading.gif`} alt='loader' />
      </div> 
    );
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  const currency = sessionStorage.getItem('INDIA') === 'YES' ? 'INR' : 'USD';

  const schema = {
    "@context": "https://schema.org",
    "@type": "ItemList",
    "name": data.category_name,
    "description": `Discover the best ${data.category_name} products`,
    "url": `https://www.wallfleurthings.com/category/${data.slug}`,
    "itemListElement": data.product.map((product, index) => ({
      "@type": "ListItem",
      "position": index + 1,
      "url": `https://www.wallfleurthings.com/product/${product.slug}`,
      "name": product.name,
      "image": product.image || '/default-image.jpg',
      "description": product.description || "No description available",
      "offers": {
        "@type": "Offer",
        "priceCurrency": currency,
        "price": product.price,
        "itemCondition": "https://schema.org/NewCondition",
        "availability": "https://schema.org/InStock"
      }
    }))
  };

  return (
    <>
      <MetaTags 
        title={data.category_name} 
        description={`Discover the best ${data.category_name} products`} 
        keywords={data.keywords || `${data.category_name}, eCommerce`} 
        image={data.image || '/default-image.jpg'} 
        noIndex={false}
        schema={schema}
      />
      <Navbar />
      <div className='wallfleur_row'>

          <div  className='breadcrums'><Link to="/"> Home </Link> {' / '} {data.category_name} </div>
          <div>
            <h1 className='heading-text'>{data.category_name} Category</h1>
          </div>
          <div className='category-product-list'>
            <CategoryProduct cardData={data.product} countryCurrency={selectedCountry} contentType="product" />
          </div>
      </div>
      <Footer />
      <CountryModal />
    </>
  );
}
