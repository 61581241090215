import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { PRODUCT } from '../constant/constant';
import { useNavigate } from "react-router-dom";

export default function CommonSlider({ datas, countryCurrency, contentType }) {
  console.log(datas);
  const navigate = useNavigate();

  const [shouldShowSlider, setShouldShowSlider] = useState(false);

  useEffect(() => {
    const updateSliderVisibility = () => {
      const width = window.innerWidth;
      const itemsCount = datas.length;
      
      const isIpadPro = /iPad|Macintosh/i.test(navigator.userAgent) && ('ontouchend' in document);

      if (isIpadPro) {
        setShouldShowSlider(itemsCount > 3);
      } else if (width >= 1024) {
        setShouldShowSlider(itemsCount > 4); 
      } else if (width >= 768) {
        setShouldShowSlider(itemsCount > 3); 
      } else {
        setShouldShowSlider(itemsCount > 2);
      }
    };

    updateSliderVisibility(); 
    window.addEventListener('resize', updateSliderVisibility);

    return () => {
      window.removeEventListener('resize', updateSliderVisibility);
    };
  }, [datas.length]);

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4.5,
    slidesToScroll: 2,
    arrows: true ,
    responsive: [
      {
        breakpoint: 1366,
        settings: {
          slidesToShow: 3.5,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3.5,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2.5,
          arrows:false,
        },
      },
    ],
  };

  const handleRedirectUrlClick = (url) => {
    if (url) {
      navigate(url);
    }
  };

  return (
    <div>
      {datas && datas.length > 0 ? (
        shouldShowSlider ? (
          <Slider {...settings}>
            {datas.map((data, index) => (
              <div key={index}>
                <div 
                  onClick={() => handleRedirectUrlClick(`/${contentType}/${data.slug}`)}
                  className="common-card mt-3"
                >
                  <img className="card-img-top" src={`${PRODUCT}${data.image1}`} alt="Card cap" />
                  <div className="card-body" style={{ padding: "10px 0px", textAlign: "center", fontSize: "14px" }}>
                    <p className="card-text">{data.name}</p>    
                    <p className="card-price">
                    {countryCurrency === 'India' ? (
                      <>
                        ₹ {data.price}
                      </>
                    ) : (
                      <>
                        $ {data.price}
                      </>
                    )}
                  </p>    
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        ) : (
          <div className="product-card">
            {datas.map((data, index) => (
              <div 
                key={index}
                className="prod-card" 
                onClick={() => handleRedirectUrlClick(`/${contentType}/${data.slug}`)}
              >
                <div className="prod-img">
                  <img className="card-top" src={`${PRODUCT}${data.image1}`} alt="Card image" />
                </div>
                <div className="prod-card-body">
                  <p className="prod-name">{data.name}</p>
                  <p className="prod-price">
                  {countryCurrency === 'India' ? (
                      <>
                        ₹ {data.price}
                      </>
                    ) : (
                      <>
                        $ {data.price}
                      </>
                    )}
                  </p>
                </div>
              </div>
            ))}
          </div>
        )
      ) : (
        <p>No data available</p>
      )}
    </div>
  );
}
