import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/authContext';
import { toast } from 'react-toastify';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import ProfileLeftNav from '../components/ProfileLeftNav';
import OrderDetail from '../components/OrderDetail'; 
import { PRODUCT } from '../constant/constant';
import { downloadInvoice } from '../utils/invoice';
import '../css/profile.css';
import MetaTags from '../utils/MetaTags';

const Orders = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [showOrderDetail, setShowOrderDetail] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingInvoice, setLoadingInvoice] = useState(false);
  const [loadingOrderDetail, setLoadingOrderDetail] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!user) {
      navigate('/login');
      return;
    }

    const fetchUserOrders = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}orders`, {
          headers: {
            "Authorization": `Bearer ${user}`,
            "Content-Type": "application/json"
          }
        });


        if (response.data.orderDetails === 0) {

          setOrders([]);
        } else {
          const data = response.data.orderDetails; 
          const formattedOrders = data.map(item => ({
            order: item.order,
            products: item.products
          }));
          setOrders(formattedOrders);
        }

      } catch (error) {
        console.error("Error:", error);
        toast.error('Failed to fetch user data', { position: 'top-right' });
      } finally {
        setLoading(false);
      }
    };

    fetchUserOrders();

  }, [user, navigate]);

  const handleOrderDetail = (order) => {
    setLoadingOrderDetail(true);
    setSelectedOrder(order);
    setShowOrderDetail(true);
    setLoadingOrderDetail(false);
  };
  const calculateTotals = (orders) => { 
    let total = orders.products.reduce((sum, product) => sum + (product.quantity * product.price), 0);
    let deliveryCharge;

    if (orders.order.currency === 'INR') {
        deliveryCharge = total >= 4000 ? 350 : 150;
    } else {
        deliveryCharge = total >= 130 ? 35 : 20;
    }

    const grandTotal = total + deliveryCharge;

    return { total, deliveryCharge, grandTotal };
};

  const handledownloadIvoice = async (order) => {
    setLoadingInvoice(true);
    const { total, deliveryCharge, grandTotal } = calculateTotals(order);
    await downloadInvoice(order, total, deliveryCharge, grandTotal);
    setLoadingInvoice(false);
  };

  const getStatusLabel = (status) => {
    switch (status) {
      case 'paid':
        return 'Order Confirmed!';
      case 'crafting':
        return 'Order Crafting';
      case 'Shipped':
        return 'Order Shipped';
      case 'Delivered':
        return 'Order Delivered';
      case 'Delayed':
        return 'Order Delayed';
      case 'Cancelled':
        return 'Order Cancelled';
      default:
        return 'Pending';
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  };

  const toggleShow = () => setShowOrderDetail(!showOrderDetail);

  return (
    <>
      <MetaTags 
        title="Order - Wallfleur" 
        description="Review your past orders."
        keywords="orders, order history, eCommerce"
        noIndex={true}
      />
      <Navbar />
      <div className="wallfleur_row">
        <div className="order-container">
          <ProfileLeftNav />
          <div className="main-content">
            <div className="orders_info">
              {loading && <p>Loading orders...</p>}
              {error && <p>{error}</p>}
              {orders.length === 0 && !loading && !error && <p>No orders found.</p>}
              {orders.map((orderItem, index) => (
                <div className="order_card" key={index}>
                  <div className="left_section">
                    <div className="product-order-info">
                      {orderItem.products.length > 0 &&
                        <>
                          <img src={`${PRODUCT}${orderItem.products[0].image1}`} alt="Product" />
                          <div>
                            <h2>{orderItem.products[0].name} {orderItem.products.length > 1 && `+${orderItem.products.length - 1}`}</h2>
                            <p>Rs.{orderItem.products[0].price}</p>
                            <p>{formatDate(orderItem.order.ordered_date)}</p>
                          </div>
                        </>
                      }
                    </div>
                  </div>
                  <div className="right_section">
                    <div className='order_ivoice'>
                    {orderItem.order.status == 'Delivered' && (
                          <button
                          className="download_invoice_btn"
                          onClick={() => handledownloadIvoice(orderItem)}
                        >
                        {loadingInvoice ? "Downloading..." : "Invoice"}
                        </button>
                        )}
                      <button
                        className="view_order_btn"
                        onClick={() => handleOrderDetail(orderItem)}
                      >
                        {loadingOrderDetail ? "Loading..." : "View Order Details"}
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {selectedOrder && (
          <OrderDetail
            orderInfo={selectedOrder}
            show={showOrderDetail}
            toggleShow={toggleShow}
            formatDate ={formatDate}
          />
        )}

        <Footer />
      </div>
    </>
  );
};

export default Orders;
