import React from 'react';
import { Helmet } from 'react-helmet';
import {COMMON} from '../constant/constant';

const MetaTags = ({ title, description, keywords, author, imageUrl, url, noIndex, schema }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <meta name="author" content={author} />
      {noIndex && <meta name="robots" content="noindex, nofollow" />}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={imageUrl} />
      <meta property="og:url" content={url} />
      <meta name="twitter:card" content="summary_large_image" />
      {schema && <script type="application/ld+json">{JSON.stringify(schema)}</script>}
    </Helmet>
  );
};

MetaTags.defaultProps = {
  title: 'Wallfleur',
  description: 'Default Discover unique handmade products at Wallfleurthings. Explore our collection of beautiful clay items and artisanal gifts crafted with care and creativity. Perfect for your home or as thoughtful gifts.',
  keywords: 'handmade products, clay items, artisanal gifts, ecommerce, online store, unique home decor, handcrafted gifts, Wallfleurthings',
  author: 'Wallfleur',
  imageUrl: `${COMMON}Logo.png`,
  url: 'https://www.wallfleurthings.com',
  noIndex: false,
  schema: null
};

export default MetaTags;
