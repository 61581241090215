import React, { useState, useEffect } from 'react';
import Navbar from '../components/Navbar';
import MetaTags from '../utils/MetaTags';
import { useNavigate, useLocation } from 'react-router-dom';
import { Link } from "react-router-dom";
import { useAuth } from '../context/authContext';
import axios from 'axios';

export default function Paypal() {
    const { user } = useAuth();
    const [countdown, setCountdown] = useState(5);
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const order_id = queryParams.get('order_id');

    useEffect(() => {
        console.log('Order ID from URL:', order_id);

        const timer = setInterval(() => {
            setCountdown(prevCountdown => {
                if (prevCountdown === 1) {
                    clearInterval(timer);
                    // navigate('/orders'); 
                    return 0;
                }
                return prevCountdown - 1;
            });
        }, 1000);

        return () => clearInterval(timer);
    }, []); 

    useEffect(() => {
        const capturePayPal = async () => {
            try {
                await axios.post(
                    `${process.env.REACT_APP_API_URL}capturePayPalPayment`,
                    {
                        order_id,
                    },
                    {
                        headers: {
                            "Authorization": `Bearer ${user}`,
                            "Content-Type": "application/json"
                        },
                    }
                );
            } catch (error) {
                console.log(error);
            }
        };

        capturePayPal(); 

    }, [order_id, user]); 

    return (
        <>
            <MetaTags
                noIndex={true}
            />
            <Navbar />
            <div className="paypal-confirmation">
                <h1>Thank you for purchasing our products!</h1>
                <h3>We'll confirm your payment and update your order status soon.</h3>
                <Link to='/orders'>Redirecting to orders in {countdown} seconds...</Link>
            </div>
        </>
    );
}
