import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/authContext';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import CountryModal from '../components/CountryModal';
import MainProfile from '../components/profile/MainProfile';
import ProfileLeftNav from '../components/ProfileLeftNav';
import MetaTags from '../utils/MetaTags';
import '../css/profile.css';

export default function Profile() {
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    if (!user) {
      navigate('/login');
    }
  }, [user]);

  if (!user) {
    return null;
  }

  return (
    <>
      <MetaTags 
        title="User Profile - Your eCommerce Store" 
        description="View and manage your profile details on Your eCommerce Store."
        keywords="profile, user profile, eCommerce"
        noIndex={true}
      />
      <Navbar />
      <div className="wallfleur_row">

      <div className="order-container">
      <ProfileLeftNav />

        <div className="main-content">
          <MainProfile />
        </div>
      </div>
      </div>
      <Footer />
      <CountryModal />
    </>
  );
}
