import React, { useEffect, useState } from 'react';
import { useAuth } from '../../context/authContext';
import { toast } from 'react-toastify';
import { ColorRing } from "react-loader-spinner";
import { useDispatch } from 'react-redux';
import { clearCart } from '../../redux/cartSlice';
import { redirect } from 'react-router-dom';

export default function PaymentDetails({ cardData, userData, countryCurrency, handlePaymentComplete, reduceProductQuantity, restoreProductQuantities }) {
    const { user } = useAuth();
    const [loading, setLoading] = useState(false);
    const [selectedPayment, setSelectedPayment] = useState(null);
    const dispatch = useDispatch();

    const productTotal = cardData.reduce((total, card) => total + card.price * card.quantity, 0);
    const deliveryCharge = countryCurrency === 'India'
        ? (productTotal >= 4000 ? 350 : 150)
        : (productTotal >= 130 ? 35 : 20);
    const totalAmount = productTotal + deliveryCharge;

    const loadRazorpayScript = () => {
        return new Promise((resolve) => {
            const script = document.createElement('script');
            script.src = 'https://checkout.razorpay.com/v1/checkout.js';
            script.onload = () => resolve(true);
            script.onerror = () => resolve(false);
            document.body.appendChild(script);
        });
    };

    useEffect(() => {
        if (selectedPayment === 'paypal') {
            loadPaypalScript();
        }
    }, [selectedPayment]);

    const loadPaypalScript = () => {
        const script = document.createElement('script');
        script.src = 'https://www.paypal.com/sdk/js?client-id=' + process.env.PAYPAL_CLIENT_ID;
        script.onload = () => setLoading(false);
        document.body.appendChild(script);
    };

    const handleRazorpayPayment = async () => {
        setLoading(true);
        const res = await loadRazorpayScript();
        if (!res) {
            toast.error('Razorpay SDK failed to load. Are you online?', { position: 'top-right' });
            setLoading(false);
            return;
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}createOrder`, {
                method: "POST",
                headers: {
                    "Authorization": `Bearer ${user}`,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    amount: calculateTotal(),
                    userData,
                    products: cardData.map(product => ({
                        id: product.id,
                        quantity: product.quantity,
                        name: product.name
                    }))
                })
            });

            const paymentData = await response.json();
            if (!response.ok) {
                toast.error(paymentData.message || 'An error occurred. Please try again.', { position: 'top-right' });
                setLoading(false);
                return;
            }

            await reduceProductQuantity();

            const options = {
                key: process.env.RAZORPAY_KEY_ID,
                amount: paymentData.amount,
                currency: countryCurrency === 'India' ? "INR" : "USD",
                name: "Wallfleur",
                description: "Test Transaction",
                image: "https://wallfleur-images.s3.ap-south-1.amazonaws.com/common/Logo.png",
                order_id: paymentData.id,
                handler: async (response) => {
                    const result = await fetch(`${process.env.REACT_APP_API_URL}verifyPayment`, {
                        method: "POST",
                        headers: {
                            "Authorization": `Bearer ${user}`,
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify({
                            order_id: paymentData.id,
                            payment_id: response.razorpay_payment_id,
                            signature: response.razorpay_signature
                        })
                    });

                    if (result.status === 200) {
                        toast.success('Payment successful!', { position: 'top-right' });
                        handlePaymentComplete();
                        dispatch(clearCart());
                    } else {
                        await restoreProductQuantities();
                        toast.error('Payment verification failed. Please try again.', { position: 'top-right' });
                    }
                    setLoading(false);
                },
                modal: {
                    ondismiss: () => {
                        restoreProductQuantities();
                        setLoading(false);
                        toast.info('Payment process was cancelled.', { position: 'top-right' });
                    }
                },
                prefill: {
                    name: userData.name,
                    email: userData.email,
                    contact: userData.mobile
                },
                notes: {
                    address: userData.address
                },
                theme: {
                    color: "#3399cc"
                },
                payment_capture: 1,
            };

            const paymentObject = new window.Razorpay(options);
            paymentObject.open();
        } catch (error) {
            toast.error('An error occurred during payment. Please try again.', { position: 'top-right' });
            setLoading(false);
        }
    };

    const handlePaypalPayment = async () => {
        setLoading(true);
        try {
            await reduceProductQuantity();

            const response = await fetch(`${process.env.REACT_APP_API_URL}createPayPalOrder`, {
                method: "POST",
                headers: {
                    "Authorization": `Bearer ${user}`,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    amount: totalAmount,
                    userData,
                    products: cardData.map(product => ({
                        id: product.id,
                        quantity: product.quantity,
                        name: product.name
                    }))
                })
            });
            setLoading(false);
            if (response.ok) {
                const url = await response.json();
                console.log(url);
                window.location.href = url.approvalUrl;
            } else {
                console.error('Failed to create PayPal order');
            }        
        } catch (error) { 
            toast.error('An error occurred during PayPal payment. Please try again.', { position: 'top-right' });
            setLoading(false);
        }
    };

    const handlePaymentSelection = (paymentMethod) => {
        setSelectedPayment(paymentMethod);
    };

    const handlePaymentOption = (paymentMethod) => {
        setSelectedPayment(null);
    }

    const calculateTotal = () => {
        return productTotal + deliveryCharge;
    };

    return (
        <div className='payment'>
            <h2>Select Payment Method</h2>
            {!selectedPayment && (
                <div className='payment-options'>
                    <button onClick={() => handlePaypalPayment('paypal')} disabled={loading}>
                        {loading ? (
                            <ColorRing type="TailSpin" color="#00BFFF" height={30} width={30} />
                        ) : (
                            `PayPal (International)`
                        )}
                    </button>
                    <div className="or-text">                    
                        <div className="line"></div>
                        <p>OR</p>
                        <div className="line"></div>
                    </div>
                    <button onClick={() => handlePaymentSelection('razorpay')} disabled={loading}>
                        Other Payment Options
                    </button>
                </div>
            )}

            {selectedPayment == 'razorpay' && (
                <>
                    <div className='payment-options'>
                        <button onClick={handleRazorpayPayment} disabled={loading}>
                        {loading ? (
                                <ColorRing type="TailSpin" color="#00BFFF" height={30} width={30} />
                            ) : (
                                `Pay ${totalAmount.toFixed(2)}`
                            )}
                        </button>
                        <div className="or-text">                    
                            <div className="line"></div>
                            <p>OR</p>
                            <div className="line"></div>
                        </div>
                        <button onClick={handlePaymentOption} disabled={loading}>
                            Change Payment Method
                        </button>
                    </div>
                </>
            )}
        </div>
    );
}
