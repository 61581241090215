import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useAuth } from '../context/authContext';
import { useCountry } from '../context/CountryContext';
import Navbar from '../components/Navbar';
import { toast } from 'react-toastify';
import ProductDetails from '../components/checkout/ProductDetails';
import ShippingDetails from '../components/checkout/ShippingDetails';
import PaymentDetails from '../components/checkout/PaymentDetails';
import Confirmation from '../components/checkout/Confirmation';
import MetaTags from '../utils/MetaTags';
import '../css/checkout.css';
import axios from 'axios';

export default function Checkout() {
    const navigate = useNavigate();
    const { user } = useAuth();
    const { selectedCountry } = useCountry();
    const cartItems = useSelector(state => state.cart.items);
    const [userData, setUserData] = useState({
        name: '',
        mobile: '',
        dialcode: '',
        address: '',
        city: '',
        state: '',
        postalCode: '',
        country: ''
    });
    const [step, setStep] = useState(1);
    const [showOptions, setShowOptions] = useState(false);
    const [addressAvailable, setAddressAvailable] = useState(null);

    useEffect(() => {
        if (!user) {
            navigate('/login');
        } else {
            checkAddressAvailability();
        }
    }, [user,selectedCountry]);

    useEffect(() => {
        if (cartItems.length <= 0 ) {
            navigate('/');
        }
    }, []);

    const checkAddressAvailability = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}profile`, {
                headers: {
                    "Authorization": `Bearer ${user}`,
                    "Content-Type": "application/json"
                }
            });
            if (response.data && response.data.address_1) {
                setAddressAvailable(response.data);
                setShowOptions(true);
            }
        } catch (error) {
            console.error('Error checking address availability', error);
        }
    };

    const reduceProductQuantity = async () => {
        try {
            const productsToReduce = cartItems.map(item => ({
                productId: item.id,
                quantityToReduce: item.quantity
            }));
    
            await axios.post(`${process.env.REACT_APP_API_URL}reduce-quantity`, {
                products: productsToReduce
            }, {
                headers: {
                    "Authorization": `Bearer ${user}`,
                    "Content-Type": "application/json"
                }
            });
            
        } catch (error) {
            console.error('Error reducing product quantity:', error);
        }
    };

    const restoreProductQuantities = async () => {
        try {
            const productsToRestore = cartItems.map(item => ({
                productId: item.id,
                quantityToRestore: item.quantity
            }));
    
            await axios.post(`${process.env.REACT_APP_API_URL}restore-quantity`, {
                products: productsToRestore
            }, {
                headers: {
                    "Authorization": `Bearer ${user}`,
                    "Content-Type": "application/json"
                }
            });
            
        } catch (error) {
            console.error('Error restoring product quantity:', error);
        }
    };
    
    

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUserData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleContinue = async () => {
        if (!showOptions) {
            try {
                await saveUserAddress();
                toast.success('Address saved successfully!');
            } catch (error) {
                console.error('Error saving address:', error);
                toast.error('Failed to save address.');
                return;
            }
        }
        setStep(2);
    };

    const saveUserAddress = async () => {
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}save-address`, userData, {
                headers: {
                    "Authorization": `Bearer ${user}`,
                    "Content-Type": "application/json"
                }
            });
        } catch (error) {
            throw new Error('Failed to save address');
        }
    };

    const handlePaymentComplete = () => {
        setStep(3);
    };

    const handleOptionChange = (e) => {
        if (e.target.value === 'showAddress' && addressAvailable) {
            setUserData({
                ...userData,
                name: addressAvailable.name,
                mobile: addressAvailable.phone,
                dialcode: addressAvailable.dialcode,
                address: addressAvailable.address_1,
                country: addressAvailable.country,
                state: addressAvailable.state,
                city: addressAvailable.city,
                postalCode: addressAvailable.pinCode
            });
        } else if (e.target.value === 'addNewAddress') {
            setUserData({
                name: '',
                mobile: '',
                dialcode: '',
                address: '',
                city: '',
                state: '',
                postalCode: ''
            });
        }
        setShowOptions(false);
    };

    const currency = sessionStorage.getItem('INDIA') === 'YES' ? 'INR' : 'USD';


    const orderSchema = {
        "@context": "https://schema.org",
        "@type": "Order",
        "orderNumber": "Order ID placeholder",
        "orderStatus": "Order Status placeholder",
        "orderedItem": cartItems.map(item => ({
            "@type": "Product",
            "name": item.name,
            "sku": item.sku || "N/A",
            "price": item.price,
            "priceCurrency": currency
        })),
        "billingAddress": {
            "@type": "PostalAddress",
            "streetAddress": userData.address,
            "addressLocality": userData.city,
            "addressRegion": userData.state,
            "postalCode": userData.postalCode,
            "addressCountry": userData.country
        }
    };

    return (
        <>
            <MetaTags 
            title="Checkout - Wallfleur" 
            description="Complete your purchase and review your order details on Wallfleur. Fast and secure checkout process."
            keywords="checkout, purchase, order review, eCommerce"
            noIndex={false}
            schema={orderSchema}
            />
            <Navbar />
            <div className="checkout-container">
                <div className="checkout-left">
                    <div className="multi-step-form">
                        <div className={`step ${step >= 1 ? 'active' : ''} ${step > 1 ? 'completed' : ''}`}>
                            <div className="step-number">1</div><span>Info</span>
                        </div>
                        <div className={`step ${step >= 2 ? 'active' : ''} ${step > 2 ? 'completed' : ''}`}>
                            <div className="step-number">2</div><span>Payment</span>
                        </div>
                        <div className={`step ${step >= 3 ? 'active' : ''} ${step > 3 ? 'completed' : ''}`}>
                            <div className="step-number">3</div><span>Complete</span>
                        </div>
                    </div>
                    <div className={`accordion ${step === 1 ? 'open' : ''}`}>
                        {showOptions ? (
                            <div>
                                <p className='address_option_head'>We found an existing address for you. What would you like to do?</p>
                                <div className="address_option">
                                    <label className='address_option_ans'>
                                        <input
                                            type="radio"
                                            name="addressOption"
                                            value="showAddress"
                                            onChange={handleOptionChange}
                                        />
                                        <p>{addressAvailable.address_1}, {addressAvailable.city}, {addressAvailable.state}, {addressAvailable.country}, {addressAvailable.pinCode}.</p>
                                    </label>
                                    <label className='address_option_ans'>
                                        <input
                                            type="radio"
                                            name="addressOption"
                                            value="addNewAddress"
                                            onChange={handleOptionChange}
                                        />
                                        Deliver to new Shipping/Billing address
                                    </label>
                                </div>
                            </div>
                        ) : (
                            <ShippingDetails
                                userData={userData}
                                handleInputChange={handleInputChange}
                                handleContinue={handleContinue}
                            />
                        )}
                    </div>
                    <div className={`accordion ${step === 2 ? 'open' : ''}`}>
                        <PaymentDetails
                            cardData={cartItems} 
                            userData={userData}
                            countryCurrency={selectedCountry}
                            handlePaymentComplete={handlePaymentComplete}
                            reduceProductQuantity={reduceProductQuantity}
                            restoreProductQuantities={restoreProductQuantities}
                            
                        />
                    </div>
                    <div className={`accordion ${step === 3 ? 'open' : ''}`}>
                        <Confirmation isActive={step ===3} />
                    </div>
                </div>
                <div className="checkout-right">
                    <ProductDetails
                        countryCurrency={selectedCountry}
                        cardData={cartItems} 
                    />
                </div>
            </div>
        </>
    );
}
