import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useNavigate } from 'react-router-dom';
import { ADVERTISEMENT } from '../constant/constant';


export default function Carousel({slides}) {
  const navigate = useNavigate();

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows:false,
    autoplay:true,
    autoplaySpeed: 2000,
  };

  const handleRedirectUrlClick = (url) => {
    if (url) {
      window.location.href = url;
    }
  };


   return (
    <div className='home_carousel'>
      {slides.length > 1 ? (
        <Slider {...settings}>
          {slides.map((data, index) => (
            <div key={index}>
              <div className="slide-card mt-3" onClick={() => handleRedirectUrlClick(data.banner_url)}>
                <img className="card-img-top" src={`${ADVERTISEMENT}${data.banner_image}`} alt="Card cap"/>
              </div>
            </div>
          ))}
        </Slider>
      ) : (
        <div className="slide-card mt-3" onClick={() => handleRedirectUrlClick(slides[0].banner_url)}>
          <img className="card-img-top" src={`${ADVERTISEMENT}${slides[0].banner_image}`} alt="Card cap"/>
        </div>
      )}
    </div>
  );
  
}
