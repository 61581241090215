import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import CountryModal from '../components/CountryModal';
import { Spinner } from 'react-bootstrap';
import ProductCard from '../components/product/ProductCard';
import MetaTags from '../utils/MetaTags';
import { useCountry } from '../context/CountryContext';
import {COMMON} from '../constant/constant';

export default function AllCategory() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { selectedCountry } = useCountry();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}category`);
        setData(response.data);
        console.log(response.data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false); 
      }
    };

    fetchData();
  }, [selectedCountry]);

  if (loading) {
    return (
        <div className='catloader'>
          <img src={`${COMMON}wallfleur.loading.gif`} alt='loader' />
        </div> 
      );
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  const currency = sessionStorage.getItem('INDIA') === 'YES' ? 'INR' : 'USD';

  return (
    <>
      <Navbar />
      {data && (
        <>
          <MetaTags 
            title={data.category_name} 
            description={`Discover the best ${data.category_name} products`} 
            keywords={data.keywords || `${data.category_name}, eCommerce`} 
            image={data.image || '/default-image.jpg'} 
            noIndex={false}
          />
          <div className='wallfleur_row'>
            <div className='breadcrums'>Home {' / All Category'} </div>
            <h1 className='mt-5 mb-5'>{data.category_name}</h1>
            <ProductCard datas={data} contentType="category" />
          </div>
        </>
      )}
      <Footer />
      <CountryModal />
    </>
  );
}
