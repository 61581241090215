import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { ColorRing } from "react-loader-spinner";
import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/css/intlTelInput.css';


export default function ShippingDetails({ userData, handleInputChange, handleContinue }) {
    const [errors, setErrors] = useState({});
    const [countries, setCountries] = useState([]);
    const [countrycode, setCountrycode] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [postalPattern, setPostalPattern] = useState('');
    const [loadingCities, setLoadingCities] = useState(false);
    const [loadingStates, setLoadingStates] = useState(false);
    const [loading, setLoading] = useState(false);
    const itiRef = useRef(null);
    const inputRef = useRef(null);


    useEffect(() => {
        fetchCountries();
        if (inputRef.current) {
            itiRef.current = intlTelInput(inputRef.current, {
              initialCountry: 'in',
              preferredCountries: ['in', 'us'],
              separateDialCode: true,
              utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@18.1.1/build/js/utils.js",
            });
          }

        if (userData.country) {
            fetchStates(userData.country);
        }
        if (userData.state) {
            fetchCities(userData.country, userData.state);
        }
        const sessionCountry = sessionStorage.getItem('INDIA');
        if (sessionCountry === 'YES') {
            handleInputChange({ target: { name: 'country', value: 'India' } });
            setPostalPattern(/^[1-9][0-9]{5}$/); 
            fetchStates('India');
        }
    }, []);

    const fetchCountries = async () => {
        try {
            const response = await axios.get('https://restcountries.com/v3.1/all');
            setCountries(response.data.map(country => country.name.common));
        } catch (error) {
            console.error('Error fetching countries', error);
        }
    };


    const fetchStates = async (country) => {
        setLoadingStates(true);
        try {
            const response = await axios.post('https://countriesnow.space/api/v0.1/countries/states', { country });
            setStates(response.data.data.states);
            setCountrycode(response.data.data.iso2);
        } catch (error) {
            console.error('Error fetching states', error);
        } finally {
            setLoadingStates(false);
        }
    };

    const fetchCities = async (country, state) => {
        setLoadingCities(true);
        try {
            const response = await axios.post('https://countriesnow.space/api/v0.1/countries/state/cities', { country, state });
            setCities(response.data.data);
        } catch (error) {
            console.error('Error fetching cities', error);
        } finally {
            setLoadingCities(false);
        }
    };

    const validateForm = () => {
        let isValid = true;
        const newErrors = {};
    
        if (!userData.name || !userData.name.trim()) {
            newErrors.name = 'Name is required';
            isValid = false;
        }
        const dialCodeElement = document.getElementsByClassName('iti__selected-dial-code')[0];
        const dialCode = dialCodeElement ? dialCodeElement.innerText : '+91';
        const iti = itiRef.current;

        if (!userData.mobile) {
            newErrors.name = 'Phone no. is required';
        } else if (!iti.isValidNumber()) {
            newErrors.name = 'Invalid Phone no. format';
        } else {
            userData.dialcode = dialCode;
        }
        
        if (!userData.address || !userData.address.trim()) {
            newErrors.address = 'Address Line 1 is required';
            isValid = false;
        }
        if (!userData.country || !userData.country.trim()) {
            newErrors.country = 'Country is required';
            isValid = false;
        }
        if (!userData.state || !userData.state.trim()) {
            newErrors.state = 'State is required';
            isValid = false;
        }
        if (!userData.city || !userData.city.trim()) {
            newErrors.city = 'City is required';
            isValid = false;
        }
        if (!userData.postalCode) {
            newErrors.postalCode = 'Postal Code is required';
            isValid = false;
        } else if (postalPattern && !postalPattern.test(userData.postalCode)) {
            newErrors.postalCode = 'Postal Code is invalid';
            isValid = false;
        }
    
        setErrors(newErrors);
        setLoading(false); 
        return isValid;
    };
    

    const handleSubmit = async (e) => {
        setLoading(true); 
        e.preventDefault();
        if (validateForm()) {
            try {
                await verifyPostalCode(userData.postalCode,userData.country);
                handleContinue();
                setLoading(false); 
            } catch (error) {
                setErrors({ postalCode: 'Postal Code does not match the selected city, state, and country' });
                setLoading(false); 
            }
        }
    };

    const verifyPostalCode = async (postalCode, country) => {
        try {
            const response = await axios.get(`https://api.zippopotam.us/${countrycode}/${postalCode}`);
            
            if (response.data && response.data.places) {
                const isValidCountry = response.data.country.toLowerCase() === country.toLowerCase();
                
                if (!isValidCountry) {
                    throw new Error('Postal Code does not match the selected country');
                }
            } else {
                throw new Error('Invalid response from postal code verification API');
            }
        } catch (error) {
            throw new Error('Postal Code verification failed');
        }
    };
    

    const handleCountryChange = (e) => {
        const country = e.target.value;
        handleInputChange(e);
        fetchStates(country);

        if (country === 'India') {
            setPostalPattern(/^[1-9][0-9]{5}$/);
        } else {
            setPostalPattern(null);
        }
    };

    const handleStateChange = (e) => {
        const state = e.target.value;
        handleInputChange(e);
        fetchCities(userData.country, state);
    };

    return (
        <div className="shipping-details">
            <h3>Shipping Details</h3>
            <form onSubmit={handleSubmit}>
                <div className='form-container'>
                <div className="form-group">
                    <input
                        type="text"
                        name="name"
                        placeholder='Enter Full name*'
                        value={userData.name || ''}
                        onChange={handleInputChange}
                    />
                    {errors.name && <div className="error">{errors.name}</div>}
                </div>
                <div className="form-group">
                    <input
                        type="tel"
                        ref={inputRef}
                        name="mobile"
                        value={userData.mobile || ''}
                        onChange={handleInputChange}
                    />
                    {errors.mobile && <div className="error">{errors.mobile}</div>}
                </div>
                </div>
                <div className="form-group">
                    <input
                        type="text"
                        name="address"
                        placeholder='Address*'
                        value={userData.address || ''}
                        onChange={handleInputChange}
                    />
                    {errors.address && <div className="error">{errors.address}</div>}
                </div>
                <div className="form-row">
                    <div className="form-group">
                        <select name="country" value={userData.country} onChange={handleCountryChange} disabled={sessionStorage.getItem('INDIA') === 'YES'} className={sessionStorage.getItem('INDIA') === 'YES' ? 'select-disabled' : 'select-enabled'} >
                            <option value="">Select Country*</option>
                            {countries.map((country) => (
                                <option key={country} value={country}>{country}</option>
                            ))}
                        </select>
                        {errors.country && <div className="error">{errors.country}</div>}
                    </div>
                    <div className="form-group">
                        <select name="state" value={userData.state} onChange={handleStateChange}>
                            <option value="">Select State*</option>
                            {loadingStates ? (
                                <option disabled>Loading...</option>
                            ) : (
                                states.map((state) => (
                                    <option key={state.name} value={state.name}>{state.name}</option>
                                ))
                            )}
                        </select>
                        {errors.state && <div className="error">{errors.state}</div>}
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group">
                        <select name="city" value={userData.city} onChange={handleInputChange}>
                            <option value="">Select City*</option>
                            {loadingCities ? (
                                <option disabled>Loading...</option>
                            ) : (
                                cities.map((city) => (
                                    <option key={city} value={city}>{city}</option>
                                ))
                            )}
                        </select>
                        {errors.city && <div className="error">{errors.city}</div>}
                    </div>
                    <div className="form-group">
                        <input
                            type="text"
                            name="postalCode"
                            placeholder='Enter Zip/Postal Code*'
                            value={userData.postalCode || ''}
                            onChange={handleInputChange}
                        />
                        {errors.postalCode && <div className="error">{errors.postalCode}</div>}
                    </div>
                </div>
                <div className='checkout-submit-btn'>
                <button type="submit" disabled={loading}>                    
                {loading ?
                    <div className="loading-animation">
                        <ColorRing
                            type="TailSpin"
                            color="#00BFFF"
                            height={30}
                            width={30}
                        />
                        {'Please wait...'}
                    </div> : 'Continue'}</button>
                </div>
            </form>
        </div>
    );
}
