import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Async thunk to fetch product data
export const fetchProduct = createAsyncThunk(
  'product/fetchProduct',
  async (slug, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}product/${slug}`, {
        withCredentials: true,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const productSlice = createSlice({
  name: 'product',
  initialState: {
    products: {}, // To store products by their slug
    status: 'idle', // or 'loading', 'succeeded', 'failed'
    error: null,
  },
  reducers: {
    cacheProduct: (state, action) => {
      const product = action.payload;
      state.products[product.slug] = product;
    },
    clearProductCache: (state) => {
      state.products = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProduct.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchProduct.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.products[action.payload.slug] = action.payload;
      })
      .addCase(fetchProduct.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export const { cacheProduct, clearProductCache } = productSlice.actions;
export default productSlice.reducer;
