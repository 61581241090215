import React, { useState, useEffect } from 'react';
import ImageModal from "./ImageModal";
import { PRODUCT } from '../../constant/constant';

export default function ProductSlide({ images,quantity }) {
  const [previewImage, setPreviewImage] = useState(images[0] || ''); 
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  useEffect(() => {
    if (images.length > 0) {
      setPreviewImage(images[0]);
      setSelectedImageIndex(0);
    }
  }, [images]);

  const handleMouseEnter = (image) => {
    setPreviewImage(image);
  };

  const handlePreviewClick = () => {
    setSelectedImageIndex(images.indexOf(previewImage));
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  const handleNextImage = () => {
    setSelectedImageIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };

  const handlePrevImage = () => {
    setSelectedImageIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  if (images.length === 0) return <div>No images available</div>;

  return (
    <div className="prod-container">
      <div className="thumbnails">
        {images.map((image, index) => (
          <img
            key={index}
            className="thumbnail-img"
            src={`${PRODUCT}${image}`}
            alt={`Thumbnail ${index + 1}`}
            onMouseEnter={() => handleMouseEnter(image)}
          />
        ))}
      </div>
      <div className={`preview ${quantity <= 2 ? 'sold_out' : ''}`} onClick={handlePreviewClick}>
        <img className="preview-img" src={`${PRODUCT}${previewImage}`} alt="Preview" />
        <span style={{ display: quantity <= 2 ? 'block' : 'none' }}>Out of Stock</span>
      </div>
      {modalVisible && (
        <ImageModal onClose={handleCloseModal}>
          <button className="prev-btn" onClick={handlePrevImage}></button>
          <img src={`${PRODUCT}${images[selectedImageIndex]}`} alt={`Image ${selectedImageIndex + 1}`} />
          <button className="next-btn" onClick={handleNextImage}></button>
        </ImageModal>
      )}
    </div>
  );
}
