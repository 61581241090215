import React from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import '../css/aboutus.css';

const TermsAndConditions = () => {
  return (
    <>
      <Navbar />
      <div className="policy-container">
        <div className='breadcrums'><Link to="/"> Home </Link> {' / Terms And Conditions'} </div>
        <h1>Terms and Services</h1>
        <p>Last updated: November 7, 2024</p>
        <p>This website is operated by Wallfleurthings. Throughout the site, the terms “we”, “us” and “our” refer to "Wallfleurthings", "Wallfleur", "wallfleurthings.com". By visiting our site and/or purchasing something from us, you engage in our “Service” and agree to be bound by the following terms and conditions. Wallfleurthings reserves the right to change these Terms of service periodically without prior notice.</p>

        <h2>1. PRODUCTS</h2>
        <p>
          Every item is handmade, there may be slight differences in color or shape. We always seek for the best quality and put a lot of care in each piece, however, due to the nature of our handmade items, they may have small flaws due to human touch.
        </p>

        <h2>2. COLORS</h2>
        <p>
          Colors may vary according to each screen. We cannot guarantee that the photo you see online is 100% accurate to the real product.
        </p>

        <h2>3. RETURN/ CANCELLATIONS/ EXCHANGE </h2>
        <p>
          All sales are final. Due to the handmade nature of our products, cancellations, refunds and returns are not possible. Please make sure you're certain before you order.
        </p>

        <h2>4. SHIPPING</h2>
        <p>
          We shall not be responsible for any delay in delivery or lost packages as these are beyond our control once shipped.
          For domestic shipping within India, please allow 3-6 business days for delivery. For international packages, please allow 3-6 weeks for delivery, as timelines may vary due to customs processing and other external factors. 
          Additionally, please note that international packages may be subject to import taxes, customs duties, and fees upon arrival, imposed by the destination country. These charges are the buyer's responsibility and are not included in the shipping costs.
        </p>

        <h2>5. DAMAGED ITEM</h2>
        <p>
        In the rare case that your order arrives broken or damaged, please contact us at wallfleurthings@gmail.com within 24 hours of delivery. To verify the damage, we require an unboxing video showing the item's condition and we will do our best to resolve the issue.
        </p>

        <h2>6. WRONG ITEM RECEIVED</h2>
        <p>
          If you received the wrong item, first off, we apologise for the mix up! Please contact us at wallfleurthings@gmail.com We will try our best to resolve the issue with you.
        </p>

        <h2>7. RETURNING PACKAGE</h2>
        <p>
          In case your package is returned to us in good condition, due to non-delivery (marked as unclaimed, incorrect address or for any other reason), we will either refund the total cart value minus the initial shipping and return shipping costs or re-ship the package to you. Please note that you will be responsible for covering the re-shipping and return shipping costs.
        </p>
      </div>
      <Footer />
    </>
  );
};

export default TermsAndConditions;
