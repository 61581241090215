import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";

export default function Confirmation({ isActive }) {
    const [countdown, setCountdown] = useState(5);
    const navigate = useNavigate();

    useEffect(() => {
        if (isActive) {
            const timer = setInterval(() => {
                setCountdown(prevCountdown => {
                    if (prevCountdown === 1) {
                        clearInterval(timer);
                        navigate('/orders');
                        return 0;
                    }
                    return prevCountdown - 1;
                });
            }, 1000);
            return () => clearInterval(timer);
        }
    }, [isActive, navigate]);

    return (
        <div className="confirmation">
            <h2>Thank you for purchasing our products!</h2>
            <p>We'll confirm your payment and update your order status soon.</p>
            <Link to='/orders'>Redirecting to orders in {countdown} seconds...</Link>
        </div>
    );
}
