import React from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

export default function ProductBagDetails({ cardData, disablecheckout }) {
    // Calculate the total amount by considering quantity
    const productTotal = cardData.reduce((total, card) => total + (card.price * card.quantity), 0);
    const totalAmount = productTotal;

    const handleCheckoutClick = (e) => {
        if (disablecheckout) {
            e.preventDefault();
            toast.error('Some products may have exceeded maximum quantity available, Please update the quantity to proceed the checkout', {
                position: 'top-right'
            });
        }
    };

    return (
        <div className="product-details">
            <h3>
                Subtotal ({cardData.length} {cardData.length === 1 ? "item" : "items"})
            </h3>
            <div className="bag-summary">
                <div className="bag-summary-item total">
                    <span>SubTotal:</span>
                    <span>₹{totalAmount.toFixed(2)}</span>
                </div>
                {cardData.length > 0 && (
                    <div className="checkout-btn-container" style={{ textAlign: "center", margin: "20px 0" }}>
                        {disablecheckout ? (
                            <button 
                                className="checkout-btn" 
                                style={{ padding: "10px 20px", fontSize: "16px", cursor: "not-allowed", opacity: 0.6 }} 
                                onClick={handleCheckoutClick}
                            >
                                Proceed to Buy
                            </button>
                        ) : (
                            <Link className="checkout-btn" to='/checkout' style={{ padding: "10px 20px", fontSize: "16px", cursor: "pointer" }} onClick={handleCheckoutClick}>
                                Proceed to Buy
                            </Link>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}
