import React from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import '../css/aboutus.css';

const PrivacyPolicy = () => {
  return (
    <>
      <Navbar />
      <div className="policy-container">
      <div  className='breadcrums'><Link to="/"> Home </Link> {' / Privacy Policy'} </div>
        <h1>Privacy Policy</h1>
        <p>Last updated: November 7, 2024</p>
        
        <p>
          At Wallfleur, we value your privacy and are committed to protecting your personal information. This Privacy Policy explains how we collect, use, and protect your personal data.
        </p>

        <h2>1. Information We Collect</h2>
        <p>
          We collect information from you when you:
        </p>
        <ul>
          <li>Place an order or make a purchase</li>
          <li>Create an account or log in</li>
          <li>Contact us through email, phone, or contact form</li>
          <li>Subscribe to our newsletter or promotional emails</li>
          <li>Interact with our website, social media, or advertising</li>
        </ul>
        <p>
          Collected information may include:
        </p>
        <ul>
          <li>Name</li>
          <li>Email address</li>
          <li>Phone number</li>
          <li>Mailing address</li>
          <li>Payment information (credit card, UPI, PayPal, etc.)</li>
          <li>IP address</li>
          <li>Browser type and version</li>
          <li>Operating system</li>
          <li>Pages visited on our website</li>
        </ul>

        <h2>2. How We Use Your Information</h2>
        <p>
          We use the collected information for:
        </p>
        <ul>
          <li>Processing orders and deliveries</li>
          <li>Providing customer support</li>
          <li>Personalizing your shopping experience</li>
          <li>Sending newsletters, promotional emails, and special offers</li>
          <li>Improving our website and services</li>
          <li>Conducting market research and analysis</li>
          <li>Complying with legal requirements</li>
        </ul>

        <h2>3. Sharing Your Information</h2>
        <p>
          We do not sell or rent your personal information to third parties. However, we may share your information with:
        </p>
        <ul>
          <li>Payment processors (e.g., PayPal,PUI)</li>
          <li>Shipping providers (e.g., IndiaPost, BlueDart)</li>
          <li>Our website hosting and maintenance partners</li>
          <li>Analytics and marketing service providers (e.g., Google Analytics)</li>
        </ul>

        <h2>4. Cookies and Tracking</h2>
        <p>
          Our website uses cookies to:
        </p>
        <ul>
          <li>Enhance browsing experience</li>
          <li>Track website usage and analytics</li>
          <li>Personalize advertising</li>
        </ul>
        <p>You can manage cookies through your browser settings.</p>

        <h2>5. Security of Your Information</h2>
        <p>
          We implement reasonable measures to protect your personal information, including:
        </p>
        <ul>
          <li>SSL encryption for secure data transfer</li>
          <li>Password protection for accounts</li>
          <li>Regular security updates and monitoring</li>
        </ul>
        <p>
          However, no method of transmission over the Internet is 100% secure.
        </p>

        <h2>6. Your Rights</h2>
        <p>
          You have the right to:
        </p>
        <ul>
          <li>Access and update your personal information</li>
          <li>Opt-out of promotional emails</li>
          <li>Request deletion of your account and data</li>
          <li>File a complaint with relevant authorities</li>
        </ul>

        <h2>7. Changes to This Privacy Policy</h2>
        <p>
          We reserve the right to update this policy at any time. Changes will be posted on this page.
        </p>
      </div>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
