import React from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import '../css/aboutus.css';

const CancellationRefundPolicy = () => {
  return (
    <>
      <Navbar />
      <div className="policy-container">
        <div className="breadcrums"><Link to="/"> Home </Link> {' / Cancellation and Refund Policy'} </div>
        <h1>Cancellation and Refund Policy</h1>
        <p>Last updated: November 7, 2024</p>

        <h2>1. Cancellation Policy</h2>
        <p>
          We do not accept cancellations after an order has been placed, as each product is handmade and prepared individually for our customers with care and attention to detail.
        </p>

        <h2>2. Refund Policy for Damaged Products</h2>
        <p>
          Refunds are only possible if the product arrives damaged. If you receive a damaged item, please contact us within 24 hours of receiving it, providing proof such as a video or photos of the damage. Refunds will be processed within 7 business days after verification. The refund will be issued to the original payment method used during purchase.
        </p>

        <h2>3. Contact Us</h2>
        <p>
          If you have any questions about this Cancellation and Refund Policy, please contact us at wallfleurthings@gmail.com.
        </p>
      </div>
      <Footer />
    </>
  );
};

export default CancellationRefundPolicy;
