import { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthProvider } from './context/authContext';
import { CountryProvider } from './context/CountryContext'; 
import { Provider } from 'react-redux';
import store from "./redux/store";
import './index.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import ProtectedRoute from "./views/ProtectedRoute";

import Home from './views/Home';
import Login from './views/Login';
import Bag from './views/Bag';
import Category from './views/Category';
import AllCategory from "./views/AllCategories";
import Product from './views/Product';
import Checkout from "./views/Checkout";
import Profile from "./views/Profile";
import Orders from "./views/Orders";
import NotFound from "./views/NotFound";
import ForgotPassword from "./views/ForgotPassword";
import ResetPassword from "./views/ResetPassword";
import TermsAndConditions from "./views/TermCondition";
import PrivacyPolicy from "./views/privacyPolicy";
import CancellationRefundPolicy from "./views/CancellationPolicy";
import ContactUs from "./views/ContactUs";
import PayPal from "./views/PayPal";

function App() {
  return (
    <Provider store={store}>
      <AuthProvider>
        <CountryProvider>
          <Router>
            <MainRoutes />
          </Router>
        </CountryProvider>
      </AuthProvider>
    </Provider>
  );
}

const MainRoutes = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0,0);
  },[location]);

  return (
    <div>
      <ToastContainer />
      <Routes>
        <Route exact path='/' element={<Home key={location.key} />} />
        <Route exact path='/login' element={<Login key={location.key} />} />
        <Route path="/bag" element={<ProtectedRoute element={<Bag />} />} />
        <Route path='/all-category' element={<AllCategory key={location.key} />} />
        <Route path='/category' element={<Category key={location.key} />} />
        <Route path='/category/:slug' element={<Category key={location.key} />} />
        <Route exact path='/product' element={<Product key={location.key} />} />
        <Route exact path='/product/:slug' element={<Product key={location.key} />} />
        <Route path="/checkout" element={<ProtectedRoute element={<Checkout />} />} />
        <Route path="/orders" element={<ProtectedRoute element={<Orders />} />} />
        <Route path="/profile" element={<ProtectedRoute element={<Profile />} />} />
        <Route exact path='/forgotpassword' element={<ForgotPassword key={location.key} />} />
        <Route exact path='/resetpassword' element={<ResetPassword key={location.key} />} />
        <Route exact path='/terms-condition' element={<TermsAndConditions key={location.key} />} />
        <Route exact path='/privacy-policy' element={<PrivacyPolicy key={location.key} />} />
        <Route exact path='/cancellation-policy' element={<CancellationRefundPolicy key={location.key} />} />
        <Route exact path='/contact-us' element={<ContactUs key={location.key} />} />
        <Route exact path='/paypal/' element={<PayPal key={location.key} />} />
        <Route path="*" element={<NotFound key={location.key} />} />
      </Routes>
    </div>
  );
};

export default App;
