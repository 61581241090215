import React, { useState,useEffect } from 'react';
import { Link,useNavigate } from 'react-router-dom';
import { COMMON,PRODUCT } from '../constant/constant';

export default function CategoryProduct({ cardData,countryCurrency, contentType }) {
  const navigate = useNavigate();

  const handleRedirectUrlClick = (url) => {
    if (url) {
      navigate(url);
    }
  };

  return (
    <>
      <div className="product-card">
        {cardData.map((card) => (
          <div key={card._id} className="prod-card"  onClick={() => handleRedirectUrlClick(`/${contentType}/${card.slug}`)}>
          <div className={`prod-img ${card.quantity <= 2 ? 'sold_out' : ''}`}>
              <img className="card-top " src={`${PRODUCT}${card.image1}`} alt={`Card ${card._id} cap`} />
              <span style={{ display: card.quantity <= 2 ? 'block' : 'none' }}>Out of Stock</span>
          </div>
          <div className="prod-card-body">
            <div className="prod-content">
              <Link to={`/${contentType}/${card.slug}`} className="prod-name">{card.name}</Link>
              <p className="prod-price">
              {countryCurrency === 'India' ? (
                <>
                  ₹ {card.price}
                </>
              ) : (
                <>
                  $ {card.price}
                </>
              )}
                </p>
            </div>
          </div>
        </div>
        ))}
      </div>
    </>
  );
}