import React, { useState, useEffect } from 'react';
import { Button, Container, Card, Form, Alert } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useLocation,useNavigate } from 'react-router-dom';
import Navbar from '../components/Navbar';

const ForgotPassword = () => {
  const location = useLocation();
  const [step, setStep] = useState('request')
  const [email, setEmail] = useState('');
  const [token, setToken] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tokenFromUrl = searchParams.get('token');
    if (tokenFromUrl) {
      setToken(tokenFromUrl);
      setStep('reset');
    }
  }, [location]);

  const handleRequestReset = async (e) => {
    e.preventDefault();
    setErrors({});
    
    if (!email.trim()) {
      setErrors(prev => ({ ...prev, email: 'Email is required' }));
      return;
    }

    setIsLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}forgot-password`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email })
      });

      if (response.ok) {
        toast.success('Password reset link sent! Please check your email.', { position: 'top-right' });
      } else {
        const errorData = await response.json();
        setErrors({ email: errorData.message });
      }
    } catch (error) {
      console.error('Error:', error);
      setErrors({ email: 'An error occurred. Please try again later.' });
    }
    setIsLoading(false);
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setErrors({});
    
    if (!newPassword.trim() || newPassword.length < 8) {
      setErrors(prev => ({ ...prev, newPassword: 'Password must be at least 8 characters' }));
      return;
    }
    if (newPassword !== confirmNewPassword) {
      setErrors(prev => ({ ...prev, confirmNewPassword: 'Passwords do not match' }));
      return;
    }

    setIsLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}reset-password`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token, newPassword })
      });

      if (response.ok) {
        toast.success('Password reset successfully! You can now log in.', { position: 'top-right' });
        setMessage('Password reset successfully! You can now log in.');
      } else {
        const errorData = await response.json();
        setErrors({ token: errorData.message });
      }
    } catch (error) {
      console.error('Error:', error);
      setErrors({ token: 'An error occurred. Please try again later.' });
    }
    setIsLoading(false);
  };

  return (
    <>
    <Navbar/>
    <Container className="auth-container">
      <div className="auth-form">
        <Card className="shadow">
          <Card.Body>
            <div className="mb-3 mt-md-4">
              {step === 'request' ? (
                <>
                  <h2 className="fw-bold mb-2 text-uppercase">Forgot Password</h2>
                  {errors.email && <Alert variant="danger">{errors.email}</Alert>}
                  <Form onSubmit={handleRequestReset}>
                    <Form.Group className="mb-4">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Enter your email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </Form.Group>
                    <Button className="w-100" variant="primary" type="submit" disabled={isLoading}>
                      {isLoading ? 'Sending...' : 'Send Reset Link'}
                    </Button>
                  </Form>
                </>
              ) : (
                <>
                  <h2 className="fw-bold mb-2 text-uppercase">Reset Password</h2>
                  {errors.token && <Alert variant="danger">{errors.token}</Alert>}
                  {errors.newPassword && <Alert variant="danger">{errors.newPassword}</Alert>}
                  {errors.confirmNewPassword && <Alert variant="danger">{errors.confirmNewPassword}</Alert>}
                  <Form onSubmit={handleResetPassword}>
                    <Form.Group className="mb-4">
                      <Form.Label>New Password</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Enter new password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        required
                      />
                    </Form.Group>
                    <Form.Group className="mb-5">
                      <Form.Label>Confirm New Password</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Confirm new password"
                        value={confirmNewPassword}
                        onChange={(e) => setConfirmNewPassword(e.target.value)}
                        required
                      />
                    </Form.Group>
                    <Button className="w-100" variant="primary" type="submit" disabled={isLoading}>
                      {isLoading ? 'Resetting...' : 'Reset Password'}
                    </Button>
                    {message && <Alert variant="success" className="mt-3">{message}</Alert>}
                  </Form>
                </>
              )}
            </div>
          </Card.Body>
        </Card>
      </div>
    </Container>
    </>
  );
};

export default ForgotPassword;
