import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import ProductSlide from '../components/product/ProductSlide';
import CommonSlider from '../components/CommonSlider';
import CountryModal from '../components/CountryModal';
import LoginModal from '../components/LoginModal';
import { useAuth } from '../context/authContext';
import { useCountry } from '../context/CountryContext';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Spinner } from 'react-bootstrap';
import { useParams, Link } from 'react-router-dom';
import { useDispatch,useSelector } from 'react-redux';
import { addToCart } from '../redux/cartSlice';
import MetaTags from '../utils/MetaTags';
import { COMMON } from '../constant/constant';



export default function Product() {
  const { slug } = useParams();
  const [data, setData] = useState(null);
  const [alternatedata, setAlternateData] = useState(null);
  const [productQuantity, setProductQuantity] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { user } = useAuth();
  const { selectedCountry } = useCountry();
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [queuedProduct, setQueuedProduct] = useState(null);
  const [selectedQuantity, setSelectedQuantity] = useState(1);
  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false);
  const [addToCartLoading, setAddToCartLoading] = useState(false);
  const dispatch = useDispatch();
  const cart = useSelector(state => state.cart.items);
  const [showMore, setShowMore] = useState(false);

  const questions = [
    {
      question: "Q. What if I receive a broken or damaged item?",
      answer: "A. Please notify us within 24 hours of delivery. To verify the damage, we require an unboxing video showing the item's condition. We'll work with you to resolve the issue."
    },
    {
      question: "Q. What happens if my package is lost or delayed?",
      answer: "A. Once shipped, packages are beyond our control. We are not liable for lost or delayed packages. However, we'll do our best to assist you in resolving the issue."
    },
    {
      question: "Q. How do I care for my handmade item?",
      answer: "A. To maintain longevity, avoid exposing it to water, direct sunlight, or extreme temperatures."
    },
    {
      question: "Q. Can I cancel or change my order after it's been placed?",
      answer: "A.  No, all sales are final. We do not accept cancellations or changes once an order is placed."
    },
    {
      question: "Q. Are your products handmade?",
      answer: "A. Yes, all our products are carefully handmade with love and attention to detail."
    },
    {
      question: "Q. Can I return or exchange an item?",
      answer: "A. Due to the handmade nature of our products, we do not accept returns or exchanges."
    },
    {
      question: "Q. What payment methods do you accept?",
      answer: "A. We accept Bank Transfer, UPI, and PayPal."
    },
    {
      question: "Q. Do you offer Cash on Delivery (COD)?",
      answer: "A. No, we do not offer COD. Payment must be made online at the time of ordering."
    }
  ];

  useEffect(() => {
    const fetchDataAndAlternates = async () => {
      setLoading(true);
      try {
        await fetchData(slug);
        await fetchAlternateData(slug);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    if (slug) {
      fetchDataAndAlternates();
    }
  }, [slug,selectedCountry]);

  const fetchData = async (slug) => {
    setLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}product/${slug}`);
      setData(response.data);
      setProductQuantity(response.data[0].quantity)
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchAlternateData = async (slug) => {
    setLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}alternate-product/${slug}`);
      setAlternateData(response.data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user && queuedProduct) {
      setAddToCartLoading(true);
  
      const existingProduct = cart.find(item => item.id === queuedProduct.id);
      const totalQuantity = existingProduct ? existingProduct.quantity + queuedProduct.quantity : queuedProduct.quantity;
  
      if (totalQuantity > queuedProduct.maxquantity) {
        toast.error(`Already reached maximum quantity of ${queuedProduct.maxquantity} ${queuedProduct.name}(s).`);
        setQueuedProduct(null);
        setAddToCartLoading(false);
        return;
      }
  
      dispatch(addToCart({ ...queuedProduct, quantity: selectedQuantity }));
      toast.success(`${queuedProduct.name} added to cart!`);
      setQueuedProduct(null);
      setAddToCartLoading(false);
    }
  }, [user, queuedProduct]);

  const handleCloseLoginModal = () => {
    setShowLoginModal(false);
  };

  const images = data && data.length > 0 ? [
    data[0].image1,
    data[0].image2,
    data[0].image3,
    data[0].image4,
    data[0].image5,
    data[0].image6,
  ].filter(image => image !== undefined && image !== null && image !== '') : [];

  

  const handleQuantityChange = (change) => {
    setSelectedQuantity(prevQuantity => {
      let newQuantity = prevQuantity + change;
  
      if (data && data.length > 0) {

        if (newQuantity > data[0].quantity) {
          newQuantity = data[0].quantity;
          toast.error(`Only ${data[0].quantity} ${data[0].name}(s) available.`);
        }
        if (newQuantity > data[0].maxquantity) {
          newQuantity = data[0].maxquantity;
          toast.error(`Maximum allowed quantity is ${data[0].maxquantity} ${data[0].name}(s).`);
        }
      }
  
      if (newQuantity < 1) {
        newQuantity = 1;
      }
  
      return newQuantity;
    });
  };

  const handleAddToCartClick = () => {
    if (data && data.length > 0) {
      const product = { ...data[0], quantity: selectedQuantity };
      
      const existingProduct = cart.find(item => item.id === product.id);
  
      if (existingProduct) {
        const totalQuantity = existingProduct.quantity + selectedQuantity;
  
        if (totalQuantity > data[0].maxquantity) {
          toast.error(`Already reached maximum quantity of ${data[0].maxquantity} ${data[0].name}(s).`);
          return;
        }
      } else {
        if (selectedQuantity > data[0].quantity) {
          toast.error(`Only ${data[0].quantity} ${data[0].name}(s) available.`);
          return;
        }
      }
  
      if (user) {
        setAddToCartLoading(true);
        dispatch(addToCart(product));
        toast.success(`${product.name} added to cart!`);
        setAddToCartLoading(false); 
      } else {
        setQueuedProduct(product);
        setShowLoginModal(true);
      }
    }
  };
  

  const handleToggleDescription = () => {
    setIsDescriptionExpanded(!isDescriptionExpanded);
  };

  const truncateDescription = (text, maxWords) => {
    const words = text.split(' ');
    if (words.length > maxWords) {
      return words.slice(0, maxWords).join(' ') + '...';
    }
    return text;
  };

  const currency = selectedCountry === 'India' ? 'INR' : 'USD';

  const productSchema = data && data.length > 0 ? {
    "@context": "https://schema.org",
    "@type": "Product",
    "name": data[0].name,
    "image": data[0].imageUrl || '/default-image.jpg',
    "description": data[0].description || "No description available",
    "sku": data[0].sku || "N/A",
    "brand": {
      "@type": "Brand",
      "name": "Wallfleur"
    },
    "priceSpecification":{
      "@type": "PriceSpecification",
      "priceCurrency": currency,
      "price": data[0].price
    }
  } : {};

  return (
    <>
      {data && data.length > 0 && (
        <MetaTags
          title={`${data[0].name} - Wallfleur`}
          description={data[0].description}
          keywords={`${data[0].name}, buy ${data[0].name}, ${data[0].category_name}`}
          imageUrl={data[0].imageUrl}
          schema={productSchema}
          noIndex={false}
        />
      )}
      <Navbar />
      <div className='wallfleur_row'>
        <div className='firstfold'>
          <div className='prod_gallery'>
          {loading ? (
              <div className="skeleton skeleton-image"></div>
            ) : (
            <ProductSlide images={images} quantity={productQuantity} />
            )}
          </div>
          <div className='product-details'>
            {loading ? (
              <>
                <div className="skeleton skeleton-heading"></div>
                <div className="skeleton skeleton-text"></div>
                <div className="skeleton skeleton-text"></div>
                <div className="skeleton skeleton-button"></div>
                <div className="skeleton skeleton-button"></div>
                <div className="skeleton skeleton-desc"></div>
              </>
            ) : error ? (
              <div>Error loading product details</div>
            ) : data && data.length > 0 ? (
              <>
              <div  className='breadcrums'><Link to="/"> Home </Link> {' / '} <Link to={`/category/${data[0].category_slug}`}>{data[0].category_name} </Link> {' / '} {data[0].name}  </div>
                <h2>{data[0].name}</h2>
                <div><strong>Price: </strong> 
                {selectedCountry === 'India' ? (
                    <>
                      ₹ {data[0].price}
                    </>
                  ) : (
                    <>
                      $ {data[0].price}
                    </>
                  )}
                </div>
                <div><strong>Dimensions:</strong> {data[0].dimension}</div>
                <div className={`prod_quantity ${data[0].quantity <= 2 ? 'sold_out_btn' : ''}`}><strong>Quantity:</strong>
                  <div className="quantity-controls">
                    <button onClick={() => handleQuantityChange(-1)}>-</button>
                    <span>{selectedQuantity}</span>
                    <button onClick={() => handleQuantityChange(1)}>+</button>
                  </div>
                </div>
                <div className={`prod_button ${data[0].quantity <= 2 ? 'sold_out_btn' : ''}`}>
                  <button className='addtocart addtocartbtn' onClick={handleAddToCartClick} disabled={addToCartLoading}>
                    {addToCartLoading ? (
                      <div className="loader-container">
                        <Spinner animation="border" role="status" size="sm">
                          <span className="visually-hidden">Adding to cart...</span>
                        </Spinner>
                        <span className="loading-text">Adding to Cart...</span>
                      </div>
                    ) : (
                      <span className="text">{data[0].preorder ? 'Pre-order' : 'Add to cart'}</span>
                    )}
                  </button>
                </div>
                <div className="description">
                  {isDescriptionExpanded
                    ? data[0].description
                    : truncateDescription(data[0].description, 50)}
                  <span className="read-more" onClick={handleToggleDescription}>
                    {isDescriptionExpanded ? ' Read Less' : ' Read More'}
                  </span>
                </div>
              </>
            ) : (
              <div>No product data available</div>
            )}
          </div>
        </div>
        </div>
        <div className='product-badges'>
          <div className="nav-item mb-2 p-2"><img src={`${COMMON}porcelain.png`} alt="handmade" /><span>Handmade</span></div>
          <div className="nav-item mb-2 p-2"><img src={`${COMMON}customization.png`} alt="customization" /><span>Customisable</span></div>
          <div className="nav-item mb-2 p-2"><img src={`${COMMON}transport.png`} alt="transport" /><span>Intl. Shipping</span></div>
        </div>
        <div className='wallfleur_row'>
          {alternatedata && alternatedata.length > 0 && (
            <div className='mt-5 mb-5'>
              <h2 className='heading-text'>You may also like</h2>
              <CommonSlider datas={alternatedata} countryCurrency={selectedCountry} contentType="product" />
            </div>
          )}
          <div className='faq-section'>
          <div className="qa-section col-md-7">
              {questions.slice(0, showMore ? questions.length : 4).map((qa, index) => (
                <div key={index} className="accordion-item">
                  <div className="accordion-header" id={`heading${index}`}>
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#collapse${index}`}
                      aria-expanded="true"
                      aria-controls={`collapse${index}`}
                    >
                      {qa.question}
                    </button>
                  </div>
                  <div id={`collapse${index}`} className="accordion-collapse collapse" aria-labelledby={`heading${index}`}>
                    <div className="accordion-body">
                      {qa.answer}
                    </div>
                  </div>
                </div>
              ))}
              {!showMore && (
                <button className="btn qa_seemore" onClick={() => setShowMore(true)}>
                  See More
                </button>
              )}
          </div>
          <div className='heading-text'>
            <h2>Product Information & Common Questions</h2>
          </div>
        </div>
      </div>
      <Footer />
      <CountryModal />
      {showLoginModal && <LoginModal onClose={handleCloseLoginModal} />}
      <ToastContainer />
    </>
  );
}
