import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import dummy from '../assets/dummmy.jpeg';

export default function ProductBag({cardData,handleRemoveFromCard}) {
    const url = 'product';
    return (
        <>
            <div className='bag-prod-container'>
                {cardData.map((card) => (
                    <div key={card._id} className="bag-prod-card">
                        <div className="prod-img">
                            <img className="card-top" src={dummy} alt={`Card ${card._id} cap`} />
                        </div>
                        <div className="prod-card-body">
                            <Link to={`/${url}/${card.slug}`}>
                                <div className="prod-content">
                                    <p className="prod-name">{card.name}</p>
                                    <p className="prod-price">Price: ${card.price}</p>
                                    <p className="prod-price">Quantity: {card.quantity}</p>
                                </div>
                            </Link>
                            <div className="prod-btn">
                                <button className="removefromcard" onClick={() => handleRemoveFromCard(card.id)}>Delete</button>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
}
