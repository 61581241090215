import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { COMMON } from '../constant/constant';
import CountryContext from '../context/CountryContext';

const CountryModal = () => {
  const { selectedCountry, updateCountry } = useContext(CountryContext);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const modalValue = sessionStorage.getItem('INDIA');
    if (!modalValue) {
      setTimeout(() => {
        setShowModal(true);
      }, 10000);
    }
  }, []);

  const handleButtonClick = async (value) => {
    sessionStorage.setItem('INDIA', value);
    
    if (value === 'NO') {
      await setInternationalSession();
      updateCountry('Global');
      window.location.reload();
    }
    setShowModal(false);
  };

  const setInternationalSession = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}set-international-session`, 
        {},
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
    } catch (error) {
      console.error('Error calling API:', error);
    }
  };

  return (
    <div>
      {showModal && (
        <div className="countrymodal">
          <div className="modal-content">
            <p>Select Region/Currency</p>
            <div className='countrybtn'>
              <div className="country-option" onClick={() => handleButtonClick('YES')}>
                <img src={`${COMMON}flag.png`} alt="India Flag" style={{ height: "70px", width: "70px" }} />
                <p>India/IND</p>
              </div>
              <div className="country-option" onClick={() => handleButtonClick('NO')}>
                <img src={`${COMMON}global.png`} alt="Globe" style={{ height: "70px", width: "70px" }} />
                <p>Global/USD</p>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className={showModal ? "backdrop blur" : "backdrop"} />
    </div>
  );
};

export default CountryModal;
