import React, { useRef, useState, useEffect } from 'react';
import { Navbar, Container, Nav, Form, FormControl, Button, Offcanvas } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useAuth } from '../context/authContext';
import { toast } from 'react-toastify';
import SearchBar from './Search';
import {COMMON} from '../constant/constant';

const MyNavbar = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const searchInputRef = useRef(null);
  const [menuVisible, setMenuVisible] = useState(false);
  const { user, logout } = useAuth();
  const [searchResults, setSearchResults] = useState([]);
  const items = useSelector((state) => state.cart.items);
  const cartCount = items.length;
  const menuRef = useRef(null);

  const handleSearchClick = () => {
    setShowSearch(!showSearch);
    setShowMenu(false);
    if (!showSearch && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleMenuClick = () => {
    setMenuVisible(!menuVisible);
  };

  const handleCloseSearch = () => {
    setShowSearch(false);
  };

  const handleSearchResults = (results) => {
    setSearchResults(results);
  };

  const handleLogoutClick = () => {
    logout();
    toast.success('Logged out Successfully!', {
      position: 'top-right'
    });
  };

  return (
    <div className='main-navbar'>
      <div className='p-3' style={{ background: "#D4C4EC", textAlign: "center", color: "rgb(253 249 249)" }}>
        ✦Welcome to our Store✦
      </div>
      {showSearch ? (
        <SearchBar onClose={handleCloseSearch} onSearch={handleSearchResults} />
      ) : (
        <Navbar expand="lg" className='p-3'>
          <Container>
            {window.innerWidth >= 992 ? (
              <div className='search_icon'>
                <Nav.Link href="#search" onClick={handleSearchClick} className="text-grey">
                  <i className="bi bi-search"></i>
                </Nav.Link>
              </div>
            ) : (
              <Navbar.Toggle
                aria-controls="navbar-nav"
                style={{ border: "none", boxShadow: "none" }}
                onClick={() => setShowMenu(!showMenu)}
              />
            )}

            <Navbar.Brand className="mx-auto" href="/">
              <img src={`${COMMON}Logo.png`} className='wallfleur-logo' alt="Logo" />
            </Navbar.Brand>

            <Nav className="ml-auto flex-row">
              {window.innerWidth >= 992 ? (
                <>
                  <div className='bag'>
                    <Link to="/bag">
                      <i className="bi bi-bag"></i>
                      {cartCount > 0 && <span className="cart-count">{cartCount}</span>}
                    </Link>
                  </div>
                  <div className='profile' onClick={handleMenuClick}>
                    <i className="bi bi-person"></i>
                    {menuVisible && (
                    <div className='profile-menu' ref={menuRef}>
                      {user ? (
                        <div>
                          <div><Link className='menu-list' to='/profile'>My Profile</Link></div>
                          <div><Link className='menu-list' to='/orders'>My Orders</Link></div>
                          <div><Link className='menu-list' to="/all-category">All Categories</Link></div>
                          <div className='menu-list' onClick={handleLogoutClick}>Logout</div>
                        </div>
                      ) : (
                        <div>
                          <div><Link className='menu-list' to="/login">Signup</Link></div>
                          <div><Link className='menu-list' to='/all-category'>Categories</Link></div>
                        </div>
                      )}
                    </div>
                  )}
                  </div>
                </>
              ) : (
                <>
                  <Nav.Link href="#search" onClick={handleSearchClick} style={{ marginRight: "15px" }}>
                    <i className="bi bi-search"></i>
                  </Nav.Link>
                  <div className='bag'>
                    <Link to="/bag">
                      <i className="bi bi-bag"></i>
                      {cartCount > 0 && <span className="cart-count">{cartCount}</span>}
                    </Link>
                  </div>
                </>
              )}
            </Nav>

            <Offcanvas show={showMenu} onHide={() => setShowMenu(false)}>
              <Offcanvas.Header closeButton>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="flex-column">
                  <>
                    {user ? (
                      <>
                        <div>
                          <Link to="/profile">My Profile</Link>
                        </div>
                        <div>
                          <Link to="/orders">My Orders</Link>
                        </div>
                        <div>
                          <Link className='menu-list' to="/all-category">All Categories</Link>
                        </div>
                        <div className='logout' onClick={handleLogoutClick}>Logout</div>
                      </>
                    ) : (
                      <>
                        <div>
                          <Link className='menu-list' to="/login">Signup</Link>
                        </div>
                        <div>
                          <Link className='menu-list' to="/all-category">Categories</Link>
                        </div>
                      </>
                    )}
                  </>
                </Nav>
              </Offcanvas.Body>
            </Offcanvas>
          </Container>
        </Navbar>
      )}
    </div>
  );
};

export default MyNavbar;
