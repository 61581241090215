import React from 'react';
import { CATEGORY } from '../constant/constant';

const BentoGrid = ({ datas, contentType }) => {
  const handleRedirectUrlClick = (url) => {
    // Implement your redirection logic
    window.location.href = url;
  };

  return (
    <div className="bento-container">
      {datas.slice(0, 4).map((data, index) => (
        <div 
          key={index}
          className={`bento-item ${index === 0 ? 'large' : ''}`}
          onClick={() => handleRedirectUrlClick(`/${contentType}/${data.slug}`)}
          style={{
            backgroundImage: `url(${CATEGORY}${data.image})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
        >
          <div className="overlay">
            <p className="bento-name">{data.name}</p>
          </div>
        </div>
      ))}
      
      {datas.length > 4 && (
        <div className="bento-item view-all" onClick={() => handleRedirectUrlClick(`/all-category`)}>
          View all Categories
        </div>
      )}
    </div>
  );
};

export default BentoGrid;
