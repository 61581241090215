import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import MetaTags from '../utils/MetaTags';

function NotFound() {
  return (
    <>
      <MetaTags 
        title="404 - Page Not Found" 
        description="Oops! The page you're looking for does not exist. Check the URL or return to the homepage."
        keywords="404, page not found, error, website not found"
        noIndex={true}
      />
        <Navbar/>
        <div className="wallfleur_row">
            <div className='notfound'>
                <p>404</p>
                <h2>Page doesn't exist</h2>
            </div>
        <Footer/>
        </div>
    </>
  );
}

export default NotFound;
