import React from 'react';

export default function Modal({ children, onClose }) {
    
  return (
    <div className="image-modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        {children}
        <button className="close-btn" onClick={onClose}>{'X'}</button>
      </div>
    </div>
  );
}
