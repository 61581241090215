import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import { COMMON } from '../constant/constant';

export default function App() {
  const [email, setEmail] = useState('');
  const [validationError, setValidationError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const validateEmail = (email) => /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateEmail(email)) {
      setValidationError('Please enter a valid email address.');
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}subscribe`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();

      if (response.ok) {
        toast.success(data.message, { position: 'top-right' });
        setEmail('');
      } else {
        const data = await response.json();
        setValidationError(data.message || 'Something went wrong. Please try again later.');
      }
    } catch (error) {
      console.error('Error:', error);
      setValidationError('An error occurred. Please try again later.');
    }
  };

  return (
    <div className="footerbgimg">
      <footer className="py-5">
        <div className="col-md-12 d-flex justify-content-center flex-column">
          <form onSubmit={handleSubmit}>
            <h5>Subscribe for Shop Updates!</h5>
            {validationError && <p className="text-danger">{validationError}</p>}
            <div className='news-letter'>
              <div className='mail'>
                <input
                  id="newsletter1"
                  type="text"
                  className="form-control"
                  placeholder="Email address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <button type="submit" className="btn btn-primary">
                Subscribe
              </button>
            </div>
          </form>
        </div>

        <div className="d-flex justify-content-between flex-column flex-sm-row py-4 my-4 border-top">
          <div>
            <p><strong>About Wallfleur</strong></p>
            <ul className="list-unstyled d-flex flex-column">
              <li className="nav-item mb-2"><Link to="/terms-condition" target='_blank' className="nav-link p-0 text-muted">Terms & Services</Link></li>
              <li className="nav-item mb-2"><Link to="/privacy-policy" target='_blank' className="nav-link p-0 text-muted">Privacy Policy</Link></li>
              <li className="nav-item mb-2"><Link to="/cancellation-policy" target='_blank' className="nav-link p-0 text-muted">Cancellation/Refund Policies</Link></li>
              <li className="nav-item mb-2"><Link to="/contact-us" target='_blank' className="nav-link p-0 text-muted">Contact us</Link></li>
              <li className="nav-item mb-2"><a href="mailto:wallfleurthings@gmail.com" className="nav-link p-0 text-muted">wallfleurthings@gmail.com</a></li>
            </ul>
          </div>
          <div>
          <Link to="https://www.instagram.com/wallfleurthings" target='_blank' className="nav-link p-0 text-muted"><img src={`${COMMON}instagram.png`} style={{ height: "50px", width: "50px" }} alt="instagram" /></Link>
          </div>
        </div>

        <div className="d-flex flex-column flex-sm-row justify-content-between py-4 my-4 border-top">
          <ul className="list-unstyled d-flex flex-row">
            <li className="nav-item mb-2 p-2"><img src={`${COMMON}razorpay.svg`} style={{ height: "100px", width: "100px" }} alt="razorpay" /></li>
            <li className="nav-item mb-2 p-2"><img src={`${COMMON}paypal.svg`} style={{ height: "100px", width: "100px" }} alt="paypal" /></li>
          </ul>
          <p>Copyright © 2021 Wallfleurthings. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
}
