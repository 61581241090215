import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const ProfileLeftNav = () => {
  const location = useLocation();

  return (
    <div className="sidebar">
      <ul>
        <Link to="/profile">
          <li className={location.pathname === '/profile' ? 'active' : ''}>
            Profile
          </li>
        </Link>
        <Link to="/orders">
          <li className={location.pathname === '/orders' ? 'active' : ''}>
            Orders
          </li>
        </Link>
      </ul>
    </div>
  );
};

export default ProfileLeftNav;
