import React, { createContext, useState, useContext, useEffect } from 'react';

const CountryContext = createContext();

export const CountryProvider = ({ children }) => {
  const initialCountry = sessionStorage.getItem('selectedCountry') || 'India'; 
  const [selectedCountry, setSelectedCountry] = useState(initialCountry);

  const updateCountry = (country) => {
    setSelectedCountry(country);
    sessionStorage.setItem('selectedCountry', country); 
  };

  // Optional: Clear sessionStorage on component unmount
  useEffect(() => {
    return () => sessionStorage.removeItem('selectedCountry');
  }, []);

  return (
    <CountryContext.Provider value={{ selectedCountry, updateCountry }}>
      {children}
    </CountryContext.Provider>
  );
};

export const useCountry = () => {
  return useContext(CountryContext);
};

export default CountryContext;
