import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { Alert } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { COMMON } from '../constant/constant';
import '../css/aboutus.css';

const ContactUs = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [validationError, setValidationError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const validateEmail = (email) => /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email.toLowerCase());

  const handleSubmit = async (e) => { 
    e.preventDefault();

    if (name === '' || email === '' || message === '') {
      setValidationError('Fields cannot be left empty');
      return;
    }
    
    if (!validateEmail(email)) {
      setValidationError('Invalid email.');
      return;
    }
    
    setIsLoading(true);
    
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}contact-us`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: email, name: name, message: message })
      });
      
      const data = await response.json();

      if (response.ok) {
        toast.success(data.message, { position: 'top-right' });
        setName('');
        setEmail('');
        setMessage('');
      } else {
        setValidationError(data.message);
        setIsLoading(false);
      }
    } catch (error) {
      console.error('Error:', error);
      setValidationError('An error occurred. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Navbar />
      <div className="policy-container">
      <div  className='breadcrums'><Link to="/"> Home </Link> {' / Contact Us'} </div>
        <h1>Contact Us</h1>
        <p>Thank you for reaching out! Before sending us a message, please take a moment to check our FAQ page—your question may already be answered there. If you still need help, feel free to send us an email, and we’ll get back to you as soon as possible!</p>
        {validationError && <Alert variant="danger">{validationError}</Alert>}
        <div className='contact-us'>
        <div className='contact-info'>
          <div className='call-us'>
            <p className='head-text'><img src={`${COMMON}call_icon.svg`} alt="call-icon" />Call us</p>
            <p className='sub-text'>+916391113851</p>
          </div>
          <div className='contact-location'>
            <p className='head-text'><img src={`${COMMON}location.png`} alt="location-icon" />Location</p>
            <p className='sub-text'> WALLFLEUR. H/no.-76, Forest Colony,
                    Dimapur, 797112,
                    Nagaland, India.</p>
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          <div className='contact_us_form'>
            <label>Name:</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <div className='contact_us_form'>
            <label>Email:</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className='contact_us_form'>
            <label>Message:</label>
            <textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
            />
          </div>
          <button className='contact-button' type="submit" disabled={isLoading}>
            {isLoading ? 'Sending...' : 'Send Message'}
          </button>
        </form>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ContactUs;
